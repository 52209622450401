import React from "react";
import styled from "styled-components";
import { siteWidth } from "../common/variables";
import DesktopNav from "./desktop-nav";
import MobileNav from "./mobile-nav";

const Wrapper = styled.header`
  background: #151515;
  position: fixed;
  z-index: 10;
  max-width: ${siteWidth};
  width: 100%;
`;

const Header = ({ isNew }) => {
  return (
    <Wrapper>
      <DesktopNav isNew={isNew} />
      <MobileNav isNew={isNew} />
    </Wrapper>
  );
};

export default Header;
