import React, { useState } from "react";
import styled from "styled-components";
import {
  innerWidth,
  raleway,
  lessBlack,
  mainRed,
  mainWhite,
  lightRed,
  transHover,
  screen,
  emailRegex,
} from "../common/variables";
import logo from "../../images/black-logo.svg";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Link as SpyLink } from "react-scroll";
import ReCAPTCHA from "react-google-recaptcha";

// encode object for netlify form
const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const Wrapper = styled.footer`
  background: ${mainRed};
  padding: 55px 0;
  @media ${screen.medium} {
    padding: 70px 0;
  }

  .footer-row {
    display: flex;
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 25px;
    flex-direction: column;
    @media ${screen.small} {
      padding: 0 35px;
      flex-direction: row;
    }
    @media ${screen.medium} {
    }
    @media ${screen.large} {
      padding: 0;
    }

    &__heading {
      color: ${lessBlack};
      font-size: 1.2rem;
      font-weight: 900;
      font-family: ${raleway};
      margin-bottom: 20px;
      @media ${screen.small} {
        font-size: 1.52rem;
      }

      span {
        font-weight: 300;
      }
    }

    &__form {
      width: 100%;
      @media ${screen.small} {
        width: 50%;
      }

      form {
        .each-field {
          margin-bottom: 15px;
          @media ${screen.small} {
            margin-bottom: 19px;
          }
          label {
            color: ${lessBlack};
            display: block;
            font-family: ${raleway};
            font-size: 0.95rem;
            font-weight: 800;
            margin-bottom: 3px;
            @media ${screen.small} {
              font-size: 1.15rem;
              margin-bottom: 5px;
            }
          }

          input {
            border: 0;
            display: block;
            width: 100%;
            padding: 8px;
            outline-color: ${lightRed};
            font-size: 1.1rem;
            font-family: ${raleway};
            @media ${screen.small} {
              font-size: 1.15rem;
              padding: 9px;
            }
          }

          textarea {
            border: 0;
            width: 100%;
            height: 140px;
            padding: 8px;
            outline-color: ${lightRed};
            resize: none;
            font-size: 1.1rem;
            font-family: ${raleway};
            @media ${screen.small} {
              font-size: 1.15rem;
              padding: 9px;
              height: 190px;
            }
          }
        }

        .warning-text {
          color: ${lessBlack};
          display: table;
          font-family: ${raleway};
          font-size: 1rem;
          margin-top: -10px;
          margin-bottom: 10px;

          &--error {
            background: ${lessBlack};
            color: ${mainWhite};
          }
        }

        .success-text {
          color: ${mainWhite};
          display: table;
          font-family: ${raleway};
          font-size: 1.25rem;
          margin-top: -10px;
          line-height: 1.4;
          @media ${screen.small} {
            font-size: 1.5rem;
          }

          &--sending {
            margin-top: 10px;
          }
        }

        button {
          border: 0;
          background: ${mainWhite};
          border-radius: 8px;
          color: ${lessBlack};
          cursor: pointer;
          display: table;
          font-family: ${raleway};
          font-weight: 600;
          font-size: 0.95rem;
          padding: 11px 42px;
          margin-top: 5px;
          transition: ${transHover};
          outline-color: ${lightRed};
          @media ${screen.small} {
            font-size: 1rem;
            padding: 13px 57px;
            margin-top: 22px;
          }
          @media ${screen.withCursor} {
            &:hover {
              background: ${lessBlack};
              color: ${mainWhite};
            }
          }
        }
      }
    }

    &__contact-details {
      width: 100%;
      margin-top: 70px;
      @media ${screen.small} {
        padding-left: 48px;
        width: 50%;
        margin-top: 88px;
      }
      @media ${screen.medium} {
        padding-left: 100px;
      }

      .footer-logo {
        cursor: pointer;
        width: 100%;
        max-width: 280px;
        transition: ${transHover};
        @media ${screen.withCursor} {
          &:hover {
            opacity: 0.8;
          }
        }
        @media ${screen.small} {
          max-width: 100%;
        }
      }

      .footer-contact-description {
        font-family: ${raleway};
        font-size: 1.1rem;
        margin-top: 16px;
        @media ${screen.small} {
          font-size: 1.15rem;
        }
      }

      .connect-heading {
        color: ${mainWhite};
        font-family: ${raleway};
        font-size: 1.1rem;
        margin-top: 16px;
      }

      .connect-list {
        display: flex;
        margin-top: 16px;
        flex-wrap: wrap;

        &__item {
          a {
            color: ${mainWhite};
            font-size: 2.3rem;
            display: block;
            margin-right: 20px;
            margin-bottom: 5px;
            transition: ${transHover};
            @media ${screen.small} {
              font-size: 2.8rem;
              margin-right: 18px;
              margin-bottom: 0;
            }
            @media ${screen.medium} {
              font-size: 3rem;
              margin-right: 20px;
            }
            @media ${screen.withCursor} {
              &:hover {
                color: ${lessBlack};
              }
            }
          }
        }
      }
    }
  }
`;

const Footer = ({ isNew }) => {
  const [userInput, setInput] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [errorFeedback, setErrorFeedback] = useState(false);
  const [successFeedback, setSuccessFeedback] = useState(false);
  const [showLoading, setLoading] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);

  const handleInput = e => {
    setInput({ ...userInput, [e.target.name]: e.target.value });
  };

  const handleRecaptcha = e => {
    setRecaptcha(e);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (
      userInput.name.length &&
      userInput.phone.length &&
      emailRegex.test(userInput.email) &&
      userInput.message.length
    ) {
      setLoading(true);
      setErrorFeedback(false);
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "General Enquiry", ...userInput }),
      })
        .then(() => {
          setLoading(false);
          setErrorFeedback(false);

          setInput({
            name: "",
            phone: "",
            email: "",
            message: "",
          });

          setSuccessFeedback(true);
        })
        .catch(error => {
          alert(
            "We're sorry, We cannot process your enquiry at this time. Please try again in a few hours. Thank you!"
          );
        });
    } else {
      setErrorFeedback(true);
      setSuccessFeedback(false);
    }
  };

  return (
    <Wrapper id="contact">
      <div className="footer-row">
        <div className="footer-row__form">
          <h2 className="footer-row__heading">
            CONTACT US <span>/ want to know more?</span>
          </h2>
          <form
            onSubmit={handleSubmit}
            name="General Enquiry"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="General Enquiry" />
            <p className="each-field">
              <label htmlFor="name">Name*</label>
              <input
                type="text"
                name="name"
                onChange={handleInput}
                value={userInput.name}
              />
            </p>
            <p className="each-field">
              <label htmlFor="phone">Phone*</label>
              <input
                type="text"
                name="phone"
                onChange={handleInput}
                value={userInput.phone}
              />
            </p>
            <p className="each-field">
              <label htmlFor="email">Email*</label>
              <input
                type="text"
                name="email"
                onChange={handleInput}
                value={userInput.email}
              />
            </p>
            <p className="each-field">
              <label htmlFor="message">Message*</label>
              <textarea
                name="message"
                onChange={handleInput}
                value={userInput.message}
              ></textarea>
            </p>
            {!successFeedback && (
              <span
                className={
                  errorFeedback
                    ? `warning-text warning-text--error`
                    : `warning-text`
                }
              >
                *fields marked are required.
              </span>
            )}

            {successFeedback && (
              <span className="success-text">
                Your Message has been successfully sent!
              </span>
            )}

            {showLoading && (
              <span className="success-text success-text--sending">
                Sending...
              </span>
            )}

            {recaptcha && <button>SUBMIT</button>}

            {!recaptcha && (
              <ReCAPTCHA
                sitekey="6LeY59EUAAAAAE__hFn6P7zpxBXu1e7rTYG8-E1A"
                onChange={handleRecaptcha}
              />
            )}
          </form>
        </div>
        <div className="footer-row__contact-details">
          <SpyLink to="top" smooth={true}>
            <img
              className="footer-logo"
              src={logo}
              alt="Singapore Dreamin Logo"
            />
          </SpyLink>

          {isNew ? (
            <p className="footer-contact-description">
              Bringing the Salesforce community together.
            </p>
          ) : (
            <p className="footer-contact-description">
              Singapore Dreamin is a 1-day conference organized by Salesforce
              community members in Singapore for everyone - customers, admins,
              consultants, developers and partners. It will be an action-packed
              event with learning sessions for all and a great place to network
              with fellow Salesforce enthusiasts.
            </p>
          )}
          <h6 className="connect-heading">Connect with us</h6>
          {!isNew ? (
            <ul className="connect-list">
              <>
                <li className="connect-list__item">
                  <a
                    href="https://twitter.com/sgdreamin"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter />
                  </a>
                </li>
                <li className="connect-list__item">
                  <a
                    href="https://www.facebook.com/singapore.dreamin"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook />
                  </a>
                </li>
                <li className="connect-list__item">
                  <a
                    href="https://www.linkedin.com/in/singapore-dreamin-63b8b0195"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin />
                  </a>
                </li>
              </>
            </ul>
          ) : (
            <ul className="connect-list">
              <li className="connect-list__item">
                <a
                  href="https://twitter.com/sgdreamin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </a>
              </li>
              <li className="connect-list__item">
                <a
                  href="https://www.facebook.com/singapore.dreamin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
