import React from "react";
import styled from "styled-components";
import {
  screen,
  mainWhite,
  mainRed,
  raleway,
  innerWidth,
  transHover,
} from "../common/variables";
import logo from "../../images/white-logo.svg";
import { Link as SpyLink } from "react-scroll";

const Wrapper = styled.nav`
  display: none;
  @media ${screen.medium} {
    display: flex;
    max-width: ${innerWidth};
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
  }
  
  .logo {
      cursor: pointer;
      max-width: 310px;
      transition: ${transHover};
      @media ${screen.withCursor} {
        &:hover {
          opacity: 0.8;
        }
      }

      img {
        width: 100%;
      }
    }

    .nav-list {
      display: flex;

      &__item {
        a {
          cursor: pointer;
          color: ${mainWhite};
          font-family: ${raleway};
          display: block;
          margin-left: 45px;
          font-size: 1.05rem;
          transition: ${transHover};
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainRed};
            }
          }
          @media ${screen.medium} {
            margin-left: 58px;
          }
        }
      }

      .active {
        color: ${mainRed};
      }
    }
  }
`;

const DesktopNav = ({ isNew }) => {
  return (
    <Wrapper>
      <div className="logo">
        <a href="/" smooth={true}>
          <img src={logo} alt="Singapore Dreaming" />{" "}
        </a>
      </div>
      <ul className="nav-list">
        <li className="nav-list__item">
          <SpyLink to="about" smooth={true} spy={true} offset={-40}>
            ABOUT
          </SpyLink>
        </li>
        {isNew ? null : (
          <>
            <li className="nav-list__item">
              <SpyLink to="registration" smooth={true} spy={true} offset={-40}>
                REGISTRATION
              </SpyLink>
            </li>
            <li className="nav-list__item">
              <SpyLink to="agenda" smooth={true} spy={true} offset={-40}>
                AGENDA
              </SpyLink>
            </li>
          </>
        )}
        <li className="nav-list__item">
          <SpyLink to="sponsoring" smooth={true} spy={true} offset={-40}>
            SPONSORING
          </SpyLink>
        </li>
        <li className="nav-list__item">
          <SpyLink to="contact" smooth={true} spy={true} offset={-40}>
            CONTACT
          </SpyLink>
        </li>
      </ul>
    </Wrapper>
  );
};

export default DesktopNav;
