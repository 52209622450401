import { keyframes } from "styled-components";

export const mainWhite = `#ffffff`;
export const dirtyWhite = `#F4F4F4`;
export const mainRed = `#EC4C4C`;
export const lightRed = `#ff6b6b`;
export const mainBlack = `#000000`;
export const lessBlack = `#0C1D30`;
export const raleWay = `'Raleway', sans-serif`;
export const siteWidth = `1920px`;
export const innerWidth = `1090px`;

export const raleway = `'Raleway', sans-serif`;

// hover trasition
export const transHover = `all 0.2s ease-out`;

// Media queries for devices
export const screen = {
  xSmall: `(min-width: 413px)`, // larger phones
  small: `(min-width: 650px)`, // tablet vertical
  medium: `(min-width: 1160px)`, // tablet horizontal
  large: `(min-width: 1278px)`, // laptop and regular size desktop
  miniLarge: `(min-width: 1278px) and (max-height: 690px)`, // laptop with short height
  xLarge: `(min-width: 1690px)`, // huge size desktop
  withCursor: `not all and (pointer: coarse)`, // device with cursor. not touch screen devices
};

// regex for email validation
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacoty: 1;
  }
`;
