import React, { useState } from "react";
import styled from "styled-components";
import {
  mainWhite,
  mainRed,
  screen,
  transHover,
  raleway,
} from "../common/variables";
import { MdDehaze, MdClose } from "react-icons/md";
import { Link as SpyLink } from "react-scroll";
import logo from "../../images/white-logo.svg";
import { TouchScrollable } from "react-scrolllock";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";

const Wrapper = styled.nav`
  position: relative;
  height: 72px;
  z-index: 1;
  @media ${screen.medium} {
    display: none;
  }

  .navbar-logo {
    max-width: 210px;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
    z-index: 12;
  }

  .open-icon {
    cursor: pointer;
    color: ${mainWhite};
    font-size: 2.9rem;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 12;
  }

  .overlay-menu {
    background: #151515;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
    transition: ${transHover};
    overflow: auto;
    padding: 0 25px;
    .nav-list {
      padding-top: 140px;

      &__item {
        color: ${mainWhite};
        font-family: ${raleway};
        font-size: 1.5rem;
        display: block;
        margin-bottom: 30px;
      }

      .active {
        color: ${mainRed};
      }
    }

    .blob {
      padding-right: 60px;
      padding-top: 54px;
      padding-bottom: 30px;

      &__description {
        color: rgba(255, 255, 255, 0.85);
        font-family: ${raleway};
        font-size: 0.92rem;
        font-weight: 300;
      }

      &__connect-heading {
        color: ${mainWhite};
        font-family: ${raleway};
        font-size: 1.1rem;
        margin-top: 16px;
      }

      &__connect-list {
        display: flex;
        margin-top: 16px;
        flex-wrap: wrap;

        .item {
          a {
            color: ${mainWhite};
            font-size: 2rem;
            display: block;
            margin-right: 20px;
            margin-bottom: 5px;
            transition: ${transHover};
            @media ${screen.small} {
              font-size: 2.8rem;
              margin-right: 18px;
              margin-bottom: 0;
            }
            @media ${screen.medium} {
              font-size: 3rem;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
`;

const MobileNav = ({ isNew }) => {
  const [isNavOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!isNavOpen);
  };

  return (
    <Wrapper>
      {isNavOpen ? (
        <MdClose onClick={toggleNav} className="open-icon" />
      ) : (
        <MdDehaze onClick={toggleNav} className="open-icon" />
      )}
      <SpyLink
        to="top"
        smooth={true}
        onClick={() => {
          // close overlay menu when user click the navbar logo
          if (isNavOpen) setNavOpen(false);
        }}
      >
        <img className="navbar-logo" src={logo} alt="Singapore Dreamin" />
      </SpyLink>

      <TouchScrollable>
        <div
          className="overlay-menu"
          style={{
            transform: isNavOpen ? `translateX(0)` : `translateX(100%)`,
          }}
        >
          <ul className="nav-list">
            <li className="nav-list__item">
              <SpyLink
                to="about"
                smooth={true}
                spy={true}
                offset={-50}
                onClick={toggleNav}
              >
                ABOUT
              </SpyLink>
            </li>
            {isNew ? null : (
              <>
                <li className="nav-list__item">
                  <SpyLink
                    to="registration"
                    smooth={true}
                    spy={true}
                    offset={-70}
                    onClick={toggleNav}
                  >
                    REGISTRATION
                  </SpyLink>
                </li>
                <li className="nav-list__item">
                  <SpyLink
                    to="agenda"
                    smooth={true}
                    spy={true}
                    offset={-50}
                    onClick={toggleNav}
                  >
                    AGENDA
                  </SpyLink>
                </li>
              </>
            )}
            <li className="nav-list__item">
              <SpyLink
                to="sponsoring"
                smooth={true}
                spy={true}
                offset={-50}
                onClick={toggleNav}
              >
                SPONSORING
              </SpyLink>
            </li>
            <li className="nav-list__item">
              <SpyLink
                to="contact"
                smooth={true}
                spy={true}
                offset={-50}
                onClick={toggleNav}
              >
                CONTACT
              </SpyLink>
            </li>
          </ul>

          <div className="blob">
            <p className="blob__description">
              Singapore Dreamin is a 1-day conference organized by Salesforce
              community members in Singapore for everyone - customers, admins,
              consultants, developers and partners. It will be an action-packed
              event with learning sessions for all and a great place to network
              with fellow Salesforce enthusiasts.
            </p>
            <h6 className="blob__connect-heading">Connect with us</h6>
            <ul className="blob__connect-list">
              {isNew ? (
                <li className="connect-list__item">
                  <a
                    href="https://www.facebook.com/singapore.dreamin"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook />
                  </a>
                </li>
              ) : (
                <>
                  <li className="connect-list__item">
                    <a
                      href="https://twitter.com/sgdreamin"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter />
                    </a>
                  </li>
                  <li className="connect-list__item">
                    <a
                      href="https://www.facebook.com/singapore.dreamin"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li className="connect-list__item">
                    <a
                      href="https://www.linkedin.com/in/singapore-dreamin-63b8b0195"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </TouchScrollable>
    </Wrapper>
  );
};

export default MobileNav;
