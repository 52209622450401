import React from "react";
import PropTypes from "prop-types";
import Header from "../components/header";
import Footer from "../components/footer";
import styled from "styled-components";
import { siteWidth, mainWhite } from "../components/common/variables";

const SiteContainer = styled.div`
  max-width: ${siteWidth};
  background: ${mainWhite};
  margin: 0 auto;
`;

const Layout = ({ children, isNew }) => {
  return (
    <SiteContainer>
      <Header isNew={isNew} />
      <main>{children}</main>
      <Footer isNew={isNew} />
    </SiteContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
